<template>
  <div>
    <usuariosList />
  </div>
</template>

<script>
const usuariosList = () => import('@/layouts/components/Usuarios/UsuariosList.vue')

export default {
  components: {
    usuariosList,
  },
}
</script>

<style>

</style>
